<template>
  <div class="hiring-form">
    <transition name="fade-fast" mode="out-in">
      <div v-if="showSuccessMsg">
        <h1 class="white--text">Thank you.</h1>
        <p class="r-headline white--text">We'll get back to you soon.</p>
        <BaseButtonOutline class="ml-0" small @click="dismiss"
          >Great!</BaseButtonOutline
        >
      </div>
      <div v-else-if="showErrorMsg">
        <slot name="error-msg">
          <h2 class="grey--text">Well *that* didn't work.</h2>
          <p class="r-title">There was a problem sending your message.</p>
          <BaseButton
            class="ml-0"
            color="red lighten-2"
            small
            @click="showErrorMsg = false"
            >Try Again</BaseButton
          >
        </slot>
      </div>
      <VForm
        v-else
        ref="form"
        v-model="valid"
        class="contact-form"
        lazy-validation
        @submit="sendApplication"
      >
        <VTextField
          v-model="email"
          :rules="[rules.required, rules.email]"
          :color="computedFieldColor"
          type="email"
          label="email"
          v-bind="textFieldAttrs"
        />
        <VTextField
          v-model="name"
          :rules="[rules.required]"
          :color="computedFieldColor"
          label="your name"
          v-bind="textFieldAttrs"
        />
        <component
          :is="messageComponent"
          v-if="messageComponent"
          v-model="message"
          :rules="[rules.required]"
          :color="computedFieldColor"
          rows="3"
          label="tell us a little about yourself"
          v-bind="textFieldAttrs"
        />

        <VFlex>
          <FileInput
            v-model="filename"
            color="white"
            :disabled="sending"
            @fileSelect="attachFile"
          />
        </VFlex>
        <slot
          name="submit-btn"
          :sendApplication="sendApplication"
          :sending="sending"
        >
          <BaseButton
            :disabled="!valid"
            :color="color"
            class="ml-0"
            type="submit"
            :loading="sending"
            @click="sendApplication"
            >Apply Now</BaseButton
          >
        </slot>
        <BaseButtonOutline
          v-if="showDismiss"
          outline-color="grey"
          class="ml-0"
          :disabled="sending"
          @click="dismiss"
          >Cancel</BaseButtonOutline
        >
      </VForm>
    </transition>
  </div>
</template>

<script>
import api from '@services/api'
import FileInput from '@components/FileInput'
import { SITE_SLUG } from '@constants/siteDetails'

export default {
  name: 'JobApplicationForm',
  components: { FileInput },
  props: {
    color: {
      type: String,
      default: '#6CBD7D',
    },
    fieldColor: {
      type: String,
      default: '',
    },
    showDismiss: {
      type: Boolean,
      default: false,
    },
    messageComponent: {
      type: [String, Object],
      default: 'VTextField',
    },
    textFieldAttrs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      email: '',
      name: '',
      message: '',
      showSuccessMsg: false,
      showErrorMsg: false,
      sending: false,
      filename: '',
      resume: null,
      formData: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    computedFieldColor() {
      return this.fieldColor.length ? this.fieldColor : this.color
    }, // computedFieldColor
  },
  methods: {
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async sendApplication(e) {
      // Form submit
      e.preventDefault()
      if (this.$refs.form.validate()) {
        this.sending = true
        let formData = new FormData()
        formData.append('name', this.name)
        formData.append('email', this.email)
        formData.append('message', this.message)
        formData.append('form_url', window.location.href)
        formData.append('resume', this.resume)
        formData.append('form_type', 'Job Application')
        try {
          await api.post(`/sites/${SITE_SLUG}/send/contact-form`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })

          this.success()
        } catch (error) {
          this.error()
        } finally {
          this.sending = false
        }
      } else {
        return false
      }
    }, // emailTest
    success() {
      this.showSuccessMsg = true
      this.showErrorMsg = false
      this.$emit('sent')
      this.reset()
      this.resetValidation()
    }, // success
    error() {
      this.showSuccessMsg = false
      this.showErrorMsg = true
      this.$emit('error')
    }, // error
    dismiss() {
      this.showSuccessMsg = false
      this.showErrorMsg = false
      this.$emit('dismiss')
    }, // dismiss

    attachFile(file) {
      this.resume = file
      // console.log({ file })
    }, // attacheFile
  },
}
</script>
